import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import Separator from "@/components/Content/Separator.vue";
import ImageSelector from "@/components/Content/Uploader/ImageSelector.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { isRequired} from "@/services/rule-services";
import { TypeLoading } from "@/interfaces/loading";
import { ComboListOptions } from "@/utils/resolveObjectArray";
import { setStyleProperty } from "@/utils/styles";
import { isEmpty } from "lodash";
import CardColor from "@/components/Content/Colors/CardColor.vue";
import { ColorsSetting } from "../../../../interfaces/colors";

export default Vue.extend({
	name: "SettingsEdit",
	props: {},
	components: {
		CardAutocomplete,
		CardTextField,
		Separator,
		CardActions,
		ImageSelector,
		CardColor
	},
	data: () => ({
		valid: true,
		colorHeader: [
			{_attr: 'header_color_1', _color: "", _css: "--header-1-color", _title: "BackgroundColorRight"},
			{_attr: 'header_color_2', _color: "",_css: "--header-2-color", _title: "BackgroundColorLeft"}, 
			{_attr: 'user_name_color', _color: "", _css: "--user_name_color", _title: "ColorTextUserName"},
			{_attr: 'account_name_color', _color: "",_css: "--account_name_color", _title: "ColorTextAccountName"},
			{_attr: 'header_color_background_items_accounts', _color: "", _css: "--background-items-accounts", _title: "BackgroundColorItemsAccounts"},
			{_attr: 'header_color_text_items_accounts', _color: "",_css: "--text-items-accounts", _title: "ColorTextItemsAccounts"}
		],
		colorMenu: [
			{_attr: 'top_menu_color_1', _color: "", _css: "--top-menu-1-color", _title: "BackgroundColorRight"},
			{_attr: 'top_menu_color_2', _color: "",_css: "--top-menu-2-color", _title: "BackgroundColorLeft"}, 
			{_attr: 'menu_item_color', _color: "", _css: "--menu_item_color", _title: "ColorTextItemMenu"},
			{_attr: 'menu_item_hover_color', _color: "", _css: "--menu_item_hover_color", _title: "ColorHoverTextItemMenu"}
		],
		colorSubMenu: [
			{_attr: 'submenu_color_background', _color: "", _css: "--background-submenu", _title: "BackgroundColor"},
			{_attr: 'titles_color', _color: "",_css: "--text-items-submenu", _title: "ColorTextItemSubmenu"}, 
			{_attr: 'submenu_color_hover_text_item', _color: "", _css: "--text-items-hover-submenu", _title: "ColorHoverTextItemSubmenu"}
		],
		colorGeneral: [
			{_attr: 'buttons_color', _color: "", _css: "--v-secondary-base", _title: "ButtonsColor"},
			{_attr: 'links_color', _color: "",_css: "--link-color", _title: "ColorTextLinks"}, 
			{_attr: 'icons_color', _color: "", _css: "--icon-color", _title: "ColorsIcons"},
			{_attr: 'general_color_text_titles', _color: "", _css: "--title-color", _title: "ColorTextTitle"},
			{_attr: 'general_color_hover_text_links', _color: "", _css: "--link-hover-color", _title: "ColorHoverTextLinks"}
		],
		platform_name: "",
		help_url:"",
		logo:"",
		file: null
	}),
	created() {
		
	},
	async mounted() {
		this.loadData();
		await this.updateColors();
	},
	beforeDestroy() {},
	computed: {
		...mapGetters("profile", ["getColors", "account"]),
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("colors", ["setChangeColor"]),
		...mapActions("profile", ["fetchProfile"]),

		loadData(){
			this.platform_name = this.account.platform_name;
			this.help_url = this.account.help_url;
			this.logo = this.account.logo;
		},

		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit();
					break;
				case "cancel":
					await this.redirectToCampaign();
					break;
				case "restore":
					await this.updateColors();
					break;
			}
		},

		async handleChange(params: any){
			switch(params.type){
				case "header":
					this.colorHeader.find(c => c._attr == params.attr)._color = params.value;
					break
				case "menu":
					this.colorMenu.find(c => c._attr == params.attr)._color = params.value;
					break
				case "submenu":
					this.colorSubMenu.find(c => c._attr == params.attr)._color = params.value;
					break
				case "general":
					this.colorGeneral.find(c => c._attr == params.attr)._color = params.value;
					break
			}
		},

		async updateColors(){
			this.colorHeader.forEach(c => {
				c._color = this.getColors[c._attr];
			});
			this.colorMenu.forEach(c => {
				c._color = this.getColors[c._attr];
			});
			this.colorSubMenu.forEach(c => {
				c._color = this.getColors[c._attr];
			});
			this.colorGeneral.forEach(c => {
				c._color = this.getColors[c._attr];
			});
			this.loadData();
		},

		async handleSubmit(){
			await this.setLoadingData(TypeLoading.loading);
			let formData = this.parseData();
			this.setChangeColor(formData)
			.then(async (response) => {
				await this.setLoadingData();
				await this.fetchProfile();
			})
			.catch(async (err) => {await this.setLoadingData();});
		},

		async redirectToCampaign(){
			this.$router.push({ name: "CampaignsIndex" });
		},

		handleUpdateUpload(event: {
			avatar_file: string | null;
			upload_file: File | null;
		}) {
			const { avatar_file, upload_file } = event;
			this.file = upload_file;
			this.logo = avatar_file;
			setStyleProperty({property: "", value: this.logo});	
		},

		parseData(){
			let obj = {} as ColorsSetting;
			this.colorHeader.forEach(c => {
				obj[c._attr] = c._color;
			});
			this.colorMenu.forEach(c => {
				obj[c._attr] = c._color;
			});
			this.colorSubMenu.forEach(c => {
				obj[c._attr] = c._color;
			});
			this.colorGeneral.forEach(c => {
				obj[c._attr] = c._color;
				if(c._attr == "icons_color"){
					obj["header_color_icons"] = c._color;
				}
			});
			if(this.file){
				obj["logo"] = this.file;
			}
			obj["platform_name"] = this.platform_name;
			obj["help_url"] = this.help_url;
			return obj;
		}
	},	
	watch: {},
});

<template>
    <section>
        <div class="mb-2 d-flex align-center justify-start">
            <span 
                class="text-label font-weight-bold">
                {{ $t(getTitle)}}
            </span>
        </div>

        <div v-bind:style="background" class="mt-2 pa-4 d-flex align-center justify-end card-color">

            <v-btn fab x-small class="mx-2 btn__size_flat" @click="changeWhite"></v-btn>
            <v-btn dark fab x-small class="mx-2 btn__size_flat" @click="changeBlack"></v-btn>
            <v-btn fab x-small class="mx-2 btn__size_flat" @click="changeColor">
                <v-icon>
                    mdi-palette
                </v-icon>
            </v-btn>

        </div>

        <div class="mt-2 d-flex align-center justify-center card-picker">
            <v-color-picker
                v-if="showPalette"
                v-model="color"
                :value="color"
                class="fade-in-t"
                dot-size="20"
                hide-mode-switch
                mode="hexa"
                swatches-max-height="200"
            ></v-color-picker>
        </div>
    </section>
</template>

<script>
import Vue from "vue";
import { setStyleProperty } from "@/utils/styles";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "CardColor",
	props: {
        title:{
            type: String,
            required: true
        },
        setColor:{
            type: String,
            required: true
        },
        varColor:{
            type: String,
            required: true
        },
        attr:{
            type: String,
            required: true
        },
        type:{
            type: String,
            required: true
        },
    },
	components: {},
	data: () => ({
        showPalette: false,
        background:{
            'background-color': "#FFF",
            'border-color': "#FFF",
            'border': '1px solid'
        },
        defaultColors: ["#EEEEEE", "#eeeeee"],
        defaultColor: "#FFFF00"
	}),
	created() {},
	async mounted() {},
	beforeDestroy() {},
	computed: {
        ...mapGetters("profile", ["getColors"]),

        color: {
			get() {
				return this.setColor;
			},
			set(val) {
				this.$emit("change", {attr: this.attr, value: val, type: this.type});
			},
		},

        getTitle(){
            return `accountSettings.fields.${this.title}`;
        }
    },
	methods: {
        changeWhite(){
            this.showPalette = false;
            this.color = "#FFF";
            this.background['background-color'] = "#FFF";
            this.background['border-color'] = "#000";
            this.changeProperty(this.varColor, "#FFF");
        },
        changeBlack(){
            this.showPalette = false;
            this.color = "#000";
            this.background['background-color'] = "#000";
            this.background['border-color'] = "#FFF";
            this.changeProperty(this.varColor, "#000");
        },
        changeColor(){
            this.showPalette = !this.showPalette;
            this.background['background-color'] = this.color;
            this.background['border-color'] = "#000";
            this.changeProperty(this.varColor, this.color);
        },
        changeProperty(_property, _value){
			setStyleProperty({property: _property, value: _value});	
		},
        updateColor(_new){
            this.background['background-color'] = _new;
            this.background['border-color'] = this.defaultColors.includes(_new) ? "#000" : _new ;
            this.changeProperty(this.varColor, _new);
        }
    },
	watch: {
        "color"(_new, old){
            this.updateColor(_new);
        }
    },
});
</script>
